var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "stepper_container" },
      [_c("Stepper", { attrs: { step: 2 } })],
      1
    ),
    _vm._m(0),
    _c("div", { staticClass: "line_pay" }, [
      _c("div", { staticClass: "input__wrap" }, [
        _c("label", [_vm._v("發票選項")]),
        _c(
          "div",
          { staticClass: "carrier_select_bar" },
          [
            _c(
              "div",
              {
                staticClass: "carrier__selected",
                on: {
                  click: function($event) {
                    _vm.activeSelect = true
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.carrierSelect.title) +
                    "\n          "
                ),
                _c("img", {
                  attrs: { src: require("@/assets/icon_downward.svg"), alt: "" }
                })
              ]
            ),
            _c("v-slide-y-reverse-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSelect,
                      expression: "activeSelect"
                    }
                  ],
                  staticClass: "carrier_list"
                },
                _vm._l(_vm.carrierList, function(typeObj) {
                  return _c(
                    "div",
                    {
                      key: typeObj.value,
                      staticClass: "carrier_item",
                      class: {
                        active: _vm.carrierSelect.value === typeObj.value
                      },
                      on: {
                        click: function($event) {
                          return _vm.carrierSelectHandler(typeObj)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(typeObj.title) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.carrierSelect.value == "2",
              expression: "carrierSelect.value == '2'"
            }
          ],
          staticClass: "input__wrap"
        },
        [
          _c("label", { attrs: { for: "credit_number" } }, [
            _vm._v("載具條碼")
          ]),
          _c("div", { staticClass: "input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.carrierNum,
                  expression: "carrierNum"
                }
              ],
              attrs: {
                type: "text",
                oninput: "if(value.length>8){value=value.slice(0,8)}",
                placeholder: "請輸入載具條碼"
              },
              domProps: { value: _vm.carrierNum },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.carrierNum = $event.target.value
                }
              }
            })
          ])
        ]
      ),
      _vm.carrierSelect.value != "3"
        ? _c("div", { staticClass: "input__wrap pt-4" }, [
            _c("label", { attrs: { for: "credit_number" } }, [
              _vm._v("統一編號")
            ]),
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.uniformNum,
                    expression: "uniformNum"
                  }
                ],
                attrs: {
                  type: "text",
                  oninput: "if(value.length>8){value=value.slice(0,8)}",
                  placeholder: "(選填)"
                },
                domProps: { value: _vm.uniformNum },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.uniformNum = $event.target.value
                  }
                }
              })
            ])
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      {
        directives: [{ name: "preventRepeat", rawName: "v-preventRepeat" }],
        staticClass: "nextBtn",
        class: { notReady: !_vm.check || _vm.waiting },
        on: {
          click: function($event) {
            return _vm.next()
          }
        }
      },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.waiting ? "請稍候..." : "確認付款") + "\n  "
        )
      ]
    ),
    _c("form", { ref: "toPay", attrs: { action: _vm.PAY_URL, method: "post" } })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "method" }, [
      _c("div", { staticClass: "method__title" }, [_vm._v("請選擇發票")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }